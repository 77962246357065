<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";
import "@vueform/multiselect/themes/default.css";
import Lottie from "@/components/widgets/lottie.vue";
import animationData from "@/components/widgets/loading-lottie.json";
import dataReady from "@/components/widgets/checkmark-ready.json";
// import SocketMixin from '../../mixins/SocketMixins';
import axios from 'axios';
import Swal from "sweetalert2";

export default {
    // mixins: [SocketMixin],
    page: {
        title: "Starter",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
            isConected: false,
            qrCodeWa: null,
            waSatus: null,
            defaultOptions: { animationData: animationData },
            optReadyData: {animationData: dataReady},
            dataSockect: [],
            editFlag: null,
            value: null,
            statusvalue: null,
            priorityvalue: null,
            selectedimage: [],
            whatsappAccount: {},
            showRefreshQr: false,
        };
    },
    components: {
        Layout,
        lottie: Lottie,
    },
    methods: {
        disconnectSocket(){
            alert("disconnect")
            this.socket.disconnect();
            console.log(this.socket);
        },
        async unlinkWa(){
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Yes, logout it!",
            }).then(async (result) => {
                if (result.value) {
                    const unlink = await axios.post(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/disconnect", {
                        kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
                    }, {
                        headers: {
                            clientid: 'abcd1234',
                        }
                    })

                    if(unlink.data.status === 200){
                        this.isConected = false;
                        this.waSatus = 'connecting';
                        this.initSocket();
                        const kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode;
                        const config = {
                            headers: {
                                kodemb: kodemb,
                                clientid: "abcd1234"
                            }
                        }
                        await axios.get(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/qr?kodemb="+kodemb, config);
                        /* const init = await axios.post(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/init", {
                            name: JSON.parse(localStorage.getItem("user")).Company.Name,
                            kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
                        }, {
                            headers: {
                                "clientid":"abcd1234"
                            }
                        });
                        console.log(init); */
                    }
                }
            });
        },
        async initialiseAgain(){
            this.initSocket();
            
            var company = JSON.parse(localStorage.getItem("user")).Company;
            await axios.post(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/init", {
                name: company.Name,
                kodemb: company.CompanyCode,
            }, {
                headers: {
                "clientid":"abcd1234"
                }
            });

            alert("initialise sukses");
        },
        async refreshSocket()
        {
            this.socket.io.engine.close();
            this.initSocket();
            this.showRefreshQr = false;
            alert("Success refreshed please wait 10-15 second")
        }
    },
    async mounted() {
        console.log("mounted");
        const kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode;
        const config = {
            headers: {
                kodemb: kodemb,
                clientid: "abcd1234"
            }
        }

        this.waSatus = "connecting";
        const accountWA = await axios.get(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/info?kodemb="+kodemb, config);
        if (accountWA.data.status == 200) {
            console.log("Whatsapp information ready");
            const waData = accountWA.data.data
            this.whatsappAccount = waData;  
        } else {
            console.log("Whatsapp information not ready, call api fetch qr");
            let reqQR = await axios.get(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/requestQR?kodemb="+kodemb, config);
            if(reqQR.data.status == 200){
              this.waSatus = "qr_recived";
              this.qrCodeWa = reqQR.data.data;
            }
        }

        let self = this;
        setTimeout(function(){
          if(!self.qrCodeWa && !self.showRefreshQr){
            self.showRefreshQr = true;
          }
        }, 20000);
    },
    updated() {
        console.log("Updated");
    },
    async created() {
        console.log("created");
        
        this.initSocket();

        const kodeMB = JSON.parse(localStorage.getItem("user")).Company.CompanyCode
        const isReady = await axios.get(process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/status?kodemb="+kodeMB, {
            headers: {
                ClientID: "abcd1234"
            }
        });
        const resData = isReady.data
        
        if (resData.data === 'CONNECTED') {
            this.isConected = true;
            this.waSatus = 'ready';
            this.statusvalue = "Ready"
        } else {
            this.isConected = false;
            this.waSatus = 'connecting';
        }

        var self = this
        if (!this.isConected || resData.data !== 'CONNECTED') {
            this.socket.on("qr", (data) => {
                self.qrCodeWa = data;
                self.statusvalue = "QR Received"
                self.showRefreshQr = false;
                console.log(data);
            })
            this.socket.on("status", (data) => {
                self.waSatus = data;
                console.log("Status from WA ", data);
            })
        }
    },
};
</script>
    
<template>
    <Layout>

        <div class="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
            <div class="file-manager-sidebar" style="height: 100vh;">
                <div class="p-4 d-flex flex-column h-100">
                    <div class="mb-3">
                        <h3 class="text-center">Omni Channel</h3>
                    </div>

                    <div class="px-4 mx-n4" data-simplebar>
                        <ul class="to-do-menu list-unstyled" id="projectlist-data">
                            <li>
                                <a href="#velzonAdmin" class="nav-link fs-13 bg-success text-light active"> Whatsapp</a>
                            </li>
                            <li>
                                <a href-link="" class="nav-link fs-13 bg-info text-light">Telegram <span
                                        class="badge badge-gradient-danger">comming soon</span></a>
                            </li>
                            <li>
                                <a href="#skoteAdmin" class="nav-link fs-13 bg-info text-light">Messenger <span
                                        class="badge badge-gradient-danger">comming soon</span></a>
                            </li>
                            <li>
                                <a href="#ecommerceProject" class="nav-link fs-13 bg-info text-light">Line <span
                                        class="badge badge-gradient-danger">comming soon</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--end side content-->
            <div class="file-manager-content w-100 p-4 pb-0" style="height: 100vh">
                <div class="hstack mb-4">
                    <button class="btn btn-icon btn-success m-3">
                        <i class="ri-whatsapp-line" style="font-size: 25px;"></i>
                    </button>
                    <h5 class="fw-semibold flex-grow-1 mb-0"> WhatsApp</h5>
                </div>

                <div class="px-4 mx-n4" id="todo-content">
                    <div class="row justify-content-center">
                        <div class="text-center">
                            <div class="row justify-content-center">
                                <div class="col-lg-9">
                                    <h4 class="mt-4 fw-semibold" v-if="!this.isConected">Scan For Access WhatsApp Channel</h4>
                                    <h4 class="mt-4 fw-semibold" v-else>Ready Connected on WhatsApp</h4>
                                    <div v-if="!this.isConected" >
                                        <p class="text-muted mt-3">
                                            Please wait for QR Received
                                        </p>
                                        <p class="text-muted mt-3"><a href-link="" @click="initialiseAgain">Click here</a> for re-initialise</p>
                                    </div>
                                    <div v-else>
                                        <p v-if="Object.keys(this.whatsappAccount).length >= 1">
                                            Account Number : {{ this.whatsappAccount.wid.user ?? 0 }}<br>
                                            Account Name : {{ this.whatsappAccount.pushname }}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="row justify-content-center">
                                <div v-if="waSatus === 'qr_recived'" class="col-sm-7 col-8">
                                    <!-- <img src="https://www.xaviermarks.com/assets/Uploads/favico.png" style="height: 40px; position: absolute; top: 50%; left: 50%; margin: -25px 0 0 -25px; background-color: white;" /> -->
                                    <img v-bind:src="qrCodeWa" alt="" class="img-fluid" />
                                </div>
                                <div v-else-if="waSatus === 'connecting' || waSatus === 'disconnect'" class="col-sm-7 col-8">
                                    <lottie
                                        colors="primary:#0ab39c,secondary:#405189"
                                        :options="defaultOptions"
                                        :height="200"
                                        :width="200"
                                    />
                                </div>
                                <div v-else-if="waSatus === 'ready'" class="col-sm-7 col-8" style="height: 250px;">
                                    <lottie
                                        colors="primary:#0ab39c,secondary:#405189"
                                        :options="optReadyData"
                                        :height="300"
                                        :width="300"
                                    />
                                </div>
                                <div v-if="waSatus === 'ready'" class="col-sm-7 col-12">
                                    <buttom class="btn btn-danger" @click="unlinkWa">Unlink Whatsapp</buttom>
                                </div>
                                <div v-if="showRefreshQr && !waSatus" class="col-sm-7 col-12">
                                    <buttom class="btn btn-info" @click="refreshSocket">Refresh QR</buttom>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Layout>
</template>